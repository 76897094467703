<template>
  <div>
    <v-container fluid class="pt-10 search-container">
      <v-row no-gutters justify="center" class="pb-10">
        <v-col cols="12" class="mb-10">
          <join-progress :progressInfo="progressInfo" ></join-progress>
        </v-col>
        <!-- 타이틀 -->
        <v-col
          cols="12"
          class="pt-4 pb-8 reform_primary--text text-center text-h5 font-weight-bold"
          >{{ directiveInfo.title }}
        </v-col>
        <v-col
          cols="12"
          v-html="directiveInfo.content"
          class="text-center font-weight-medium"
        >
        </v-col>
        <v-col
          cols="12"
          v-html="directiveInfo.warn"
          class="text-center red--text font-weight-medium pb-10"
        >
        </v-col>
        <!-- 타이틀끝 -->
        <!-- 테이블 -->
        <v-col cols="12" md="8" lg="8" xl="8">
          <v-container fluid class="pa-0 custom-table">
            <!-- 전문과목 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 전문과목 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>전문과목 <span class="red--text">*</span></div>
              </v-col>
              <!-- 전문과목 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row>
                  <!-- 전문과목 셀렉트 -->
                  <v-col cols="6">
                    <v-select
                      hide-details
                      dense
                      outlined
                      :items="form.major.options"
                      v-model="form.major.value"
                      ref="major"
                      @input="isMajor()"
                      id="major"
                      color="reform_primary"
                      item-color="reform_primary"
                    ></v-select>
                    <p class="warn-message" v-if="form.major.hasError">
                      {{ form.major.errorTxt }}
                    </p>
                    <v-checkbox
                      hide-details
                      dense
                      label="전문과목 공개여부"
                      value="Y"
                      v-model="checkboxMajor"
                      @click="checkMajor"
                      color="reform_primary"
                    >
                    </v-checkbox>
                  </v-col>
                  <!-- 전문의번호 -->
                  <v-col cols="6">
                    <div
                      v-if="
                        form.major.value !== '항목 선택' &&
                        form.major.value !== '일반의'
                      "
                    >
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        placeholder="전문의 번호"
                        color="reform_primary"
                        v-model="form.majorNum.value"
                        @click="selectInputClick('major')"
                        @keyup="checkMajorNum()"
                        @blur="checkMajorNum()"
                      >
                      </v-text-field>

                      <div v-if="form.majorNum.hasError" class="red--text">
                        *{{ form.majorNum.errorTxt }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 전문과목 끝 -->
            <!-- 세부전문 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 세부전문 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>세부전문</div>
              </v-col>
              <!-- 세부전문 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row>
                  <!-- 세부전문 -->
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      v-model="form.majorDetail.value"
                      ref="majorDetail"
                      @keyup="checkMajorDetail()"
                      @blur="checkMajorDetail()"
                      @keyup.enter="focusControl()"
                      color="reform_primary"
                    >
                    </v-text-field>
                    <div v-if="form.majorDetail.hasError" class="red--text">
                      *{{ form.majorDetail.errorTxt }}
                    </div>
                  </v-col>
                  <!-- 세부전문의 번호 -->
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      v-if="form.majorDetail.flag"
                      hide-details
                      dense
                      placeholder="세부전문의 번호"
                      color="reform_primary"
                      v-model="form.majorDetailNum.value"
                      @click="selectInputClick('majorDetail')"
                      @keyup="checkMajorDetailNum()"
                      @blur="checkMajorDetailNum()"
                      @keyup.enter="focusControl()"
                    >
                    </v-text-field>

                    <div
                      class="warn-message red--text"
                      v-if="form.majorDetailNum.hasError"
                    >
                      *{{ form.majorDetailNum.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 세부전문 끝 -->
            <!-- 세부전공 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 세부전공 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>세부전공</div>
              </v-col>
              <!-- 세부전공 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-text-field
                  outlined
                  hide-details
                  dense
                  color="reform_primary"
                  placeholder="세부전공을 입력해주세요."
                  v-model="form.majorDeep.value"
                  @keyup="checkMajorDeep()"
                  @blur="checkMajorDeep()"
                  @keyup.enter="focusControl()"
                >
                </v-text-field>
                <div v-if="form.majorDeep.hasError" class="red--text">
                  *{{ form.majorDeep.errorTxt }}
                </div>
              </v-col>
            </v-row>
            <!-- 세부전공 끝 -->
            <!-- 출신의대 및 졸업년도 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 출신의대 및 졸업년도 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>출신의대 및 졸업년도<span class="red--text">*</span></div>
              </v-col>
              <!-- 출신의대 및 졸업년도 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row>
                  <!-- 출신의대 셀렉트 -->
                  <v-col cols="6">
                    <v-select
                      outlined
                      :class="{ others: form.univercity.value === '기타' }"
                      hide-details
                      dense
                      :items="form.univercity.options"
                      v-model="form.univercity.value"
                      ref="univercity"
                      @input="isUnivercity()"
                      color="reform_primary"
                      item-color="reform_primary"
                    ></v-select>
                    <div v-if="form.univercity.hasError" class="red--text">
                      *{{ form.univercity.errorTxt }}
                    </div>
                  </v-col>
                  <!-- 학교 공개여부 -->
                  <v-col cols="6">
                    <v-checkbox
                      hide-details
                      dense
                      label="출신학교 공개여부"
                      value="Y"
                      v-model="form.affil.affilYn"
                      color="reform_primary"
                    >
                    </v-checkbox>
                  </v-col>
                  <!-- 졸업 학교 -->
                  <v-col cols="6" v-if="form.univercity.value === '기타'">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      placeholder="학교"
                      color="reform_primary"
                      id="univercityEtc"
                      v-model="form.univercityEtc.value"
                      ref="univercityEtc"
                      @keyup="checkUnivercityEtc()"
                      @blur="checkUnivercityEtc()"
                      @keyup.enter="focusControl()"
                    >
                    </v-text-field>

                    <div v-if="form.univercityEtc.hasError" class="red--text">
                      *{{ form.univercityEtc.errorTxt }}
                    </div>
                  </v-col>
                  <!-- 졸업 년도 -->
                  <v-col
                    cols="6"
                    @click="selectInputClick('univercity')"
                    v-if="
                      form.univercity.value !== '선택안함' &&
                      form.univercity.value !== '항목 선택'
                    "
                  >
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      placeholder="졸업 년도"
                      color="reform_primary"
                      id="graduationYear"
                      v-model="form.graduationYear.value"
                      @keyup="checkGraduationYear()"
                      @blur="checkGraduationYear()"
                      @keyup.enter="focusControl()"
                      :disabled="
                        form.univercity.value === '항목 선택' ||
                        (form.univercity.value === '기타' &&
                          form.univercityEtc.value === '')
                      "
                    >
                    </v-text-field>

                    <div v-if="form.graduationYear.hasError" class="red--text">
                      *{{ form.graduationYear.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 출신의대 및 졸업년도 끝 -->
            <!-- 전공의 수련병원 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 전공의 수련병원 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>전공의 수련병원</div>
              </v-col>
              <!-- 전공의 수련병원 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      hide-details
                      dense
                      color="reform_primary"
                      placeholder="수련병원을 입력해주세요."
                      v-model="form.trainingHospital.value"
                      @keyup="checkTrainingHospital()"
                      @blur="checkTrainingHospital()"
                      @keyup.enter="focusControl()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div
                      v-if="form.trainingHospital.hasError"
                      class="red--text"
                    >
                      *{{ form.trainingHospital.errorTxt }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 전공의 수련병원 끝 -->
            <!-- 병원 검색정보 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 병원 검색정보 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원 검색정보</div>
              </v-col>
              <!-- 병원 검색정보 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-container fluid class="pa-0 ma-0">
                  <!-- 타이틀 -->
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div>
                        진단명, 증상, 수술/시술명, 보유장비, 기타 검색정보(경력,
                        동호회 명칭)등 검색에 사용될 키워드를 입력해 주세요.
                      </div>
                    </v-col>
                  </v-row>
                  <!-- 첫줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(diagnosis, index) in form.patientInfo.diagnosis"
                      :key="index + 'A'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.patientInfo.diagnosis[index]"
                        :placeholder="form.patientInfo.diagnosisPH[index]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 두줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(symptom, index) in form.patientInfo.symptom"
                      :key="index + 'B'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.patientInfo.symptom[index]"
                        :placeholder="form.patientInfo.symptomPH[index]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 세줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(operation, index) in form.patientInfo.operation"
                      :key="index + 'C'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.patientInfo.operation[index]"
                        :placeholder="form.patientInfo.operationPH[index]"
                        @keyup.enter="focusControl()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 네줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(equipment, index) in form.patientInfo.equipment"
                      :key="index + 'D'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.patientInfo.equipment[index]"
                        :placeholder="form.patientInfo.equipmentPH[index]"
                        @keyup.enter="focusControl()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 오륙줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(search, index) in form.searchInfo"
                      :key="index + 'E'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.searchInfo[index]"
                        :placeholder="form.searchInfoPlaceHolder[index]"
                        @keyup.enter="focusControl()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- 병원 검색정보 끝 -->
            <!-- 비밀 검색정보 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <!-- 비밀 검색정보 타이틀 -->
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>비밀 검색정보</div>
              </v-col>
              <!-- 비밀 검색정보 내용 -->
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-container fluid class="pa-0 ma-0">
                  <!-- 타이틀 -->
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div>
                        비밀 검색정보는 검색어로 사용되지만 검색결과에는
                        표시되지 않습니다.
                      </div>
                    </v-col>
                  </v-row>
                  <!-- 첫줄 -->
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(secret, index) in form.secretInfo"
                      :key="index + 'F'"
                    >
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        color="reform_primary"
                        v-model="form.secretInfo[index]"
                        :placeholder="form.secretInfoPlaceHolder[index]"
                        @keyup.enter="focusControl()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- 비밀 검색정보 끝 -->
          </v-container>
        </v-col>
        <!-- 테이블끝 -->
      </v-row>
      <!-- 아래버튼 -->
      <v-row justify="center">
        <v-btn
          class="search_btn-first"
          x-large
          rounded
          width="160px"
          elevation="0"
          color="#474747"
          dark
          @click="prev()"
        >
          이전으로
        </v-btn>
        <v-btn
          x-large
          rounded
          width="160px"
          elevation="0"
          @click="store()"
          class="search_btn-second"
          :class="{
            active: this.form.major.flag && !this.form.isStoreLock[0].flag,
          }"
        >
          등록완료
        </v-btn>
      </v-row>
      <button id="focusControl"></button>
    </v-container>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import JoinProgress from "@/components/join/ProgressSub";

export default {
  components: {
    DefaultPopup,
    JoinProgress
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      progressInfo: {
        activeNum: 0,
      },
      checkboxMajor: true,
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      directiveInfo: {
        title: "검색정보 입력",
        isTitleNewLine: false,
        content:
          "아래 항목은 수신용 진료의뢰서에 자동으로 입력될 내용과<br/>회원님을 다른 회원이 검색할 때 사용하는 키워드로 사용되는 내용들입니다.<br/>회원님을 효과적으로 표현할 수 있는 단어를 최대한 많이 입력해 주시기 바랍니다.",
        isContentNewLine: true,
        warn: "*표시는 수신용 진료의뢰서 작성에 필수요소입니다.",
        isWarnNewLine: false,
      },
      popupSet: {},
      form: {
        major: {
          options: [
            "일반의",
            "내과",
            "소아청소년과",
            "산부인과",
            "정신건강의학과",
            "정형외과",
            "신경외과",
            "흉부외과",
            "성형외과",
            "안과",
            "이비인후과",
            "피부과",
            "비뇨기과",
            "영상의학과",
            "방사선종양학과",
            "마취통증의학과",
            "신경과",
            "재활의학과",
            "진단검사의학과",
            "병리과",
            "예방의학과",
            "가정의학과",
            "직업환경의학과",
            "핵의학과",
            "응급의학과",
          ],
          flag: false,
          value: "" || "항목 선택",
          hasError: false,
          errorTxt: "*전공과목을 선택해주세요.",
        },
        majorNum: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "전문의 번호를 입력해주세요.",
        },
        majorDetail: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "*세부전문을 입력해주세요.",
        },
        majorDetailNum: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "세부전문의 번호를 입력해주세요.",
        },
        majorDeep: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "*세부전공을 입력해주세요.",
        },
        univercity: {
          options: [
            "선택안함",
            "서울대",
            "연세대",
            "경북대",
            "카톨릭대",
            "고려대",
            "울산대",
            "성균관대",
            "한양대",
            "경희대",
            "아주대",
            "한림대",
            "부산대",
            "인제대",
            "중앙대",
            "전남대",
            "순천향대",
            "인하대",
            "이화여대",
            "충남대",
            "가천대",
            "영남대",
            "전북대",
            "계명대",
            "동아대",
            "가톨릭관동",
            "경상대",
            "단국대",
            "충북대",
            "조선대",
            "을지대",
            "대구카톨릭대",
            "원광대",
            "건양대",
            "고신대",
            "차의과대",
            "건국대",
            "동국대",
            "강원대",
            "제주대",
            "기타",
          ],
          flag: false,
          value: "" || "항목 선택",
          hasError: false,
          errorTxt: "출신대학교를 선택해주세요.",
        },
        univercityEtc: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "출신대학교를 입력해주세요.",
        },
        graduationYear: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "졸업년도 4자리를 입력해주세요.",
        },
        trainingHospital: {
          flag: false,
          value: "",
          hasError: false,
          errorTxt: "전공의 수련병원을 입력해주세요.",
        },
        patientInfo: {
          diagnosis: ["", "", ""],
          diagnosisPH: ["갑상선암", "", ""],
          symptom: ["", "", ""],
          symptomPH: ["목멍울", "", ""],
          operation: ["", "", ""],
          operationPH: ["감상선암수술", "", ""],
          equipment: ["", "", ""],
          equipmentPH: ["신경감시장비", "", ""],
        },
        searchInfo: ["", "", "", "", "", ""],
        searchInfoPlaceHolder: ["대한이비인후과의사회", "", "", "", "", ""],
        secretInfo: ["", "", ""],
        secretInfoPlaceHolder: ["오공회", "", ""],
        isStoreLock: [
          {
            flag: false,
          },
          {
            flag: false,
          },
          {
            flag: false,
          },
        ],
        affil: {
          title: "출신학교 공개여부",
          affilYn: "",
        },
        affilMajor: {
          title: "전문과목 공개여부",
          affilMajorYn: "",
        },
      },
      isDetermined: false,
      setCount: false,
    };
  },
  created() {
    this.getSearchInfo();
    if (this.GET_PASSED_URL !== null) {
      this.checkPastUrl();
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("search", ["GET_SEARCH"]),
    ...mapGetters("util", ["GET_PASSED_URL"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("search", ["FETCH_SEARCH", "MODIFY_SEARCH", "SEARCH_SUBMIT"]),
    ...mapActions("promotion", ["FETCH_PROMOTION"]),
    checkPastUrl() {
      if (this.GET_PASSED_URL === "search") {
        this.isDetermined = true;
      }
    },
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSideIssue(subData) {
      //console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    prev() {
      //this.$router.push("/v/request/list");
      window.history.go(-1);
    },
    async getSearchInfo() {
      /** API 통신해서 받아온 문자열 */
      try {
        await this.FETCH_SEARCH().then(() => {
          //console.log("찍어", this.GET_SEARCH);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "회원정보를 서버에서 수신하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
      const fetchedSearchInfo = {
        major: this.GET_SEARCH.proObj,
        majorNum: this.GET_SEARCH.proNo,
        majorDetail: this.GET_SEARCH.dtlProObj,
        majorDetailNum: this.GET_SEARCH.dtlProNo,
        majorDeep: this.GET_SEARCH.dtlMajor,
        univercity: {
          value:
            this.GET_SEARCH.affilCollage !== ""
              ? this.GET_SEARCH.affilCollage
              : "항목 선택",
          etcValue: this.GET_SEARCH.affilCollCd,
        },
        graduationYear: this.GET_SEARCH.gradYear,
        trainingHospital: this.GET_SEARCH.trainHosp,
        patientInfo: {
          diagnosis: [
            this.GET_SEARCH.diagNm1,
            this.GET_SEARCH.diagNm2,
            this.GET_SEARCH.diagNm3,
          ],
          symptom: [
            this.GET_SEARCH.symptomNm1,
            this.GET_SEARCH.symptomNm2,
            this.GET_SEARCH.symptomNm3,
          ],
          operation: [
            this.GET_SEARCH.operNm1,
            this.GET_SEARCH.operNm2,
            this.GET_SEARCH.operNm3,
          ],
          equipment: [
            this.GET_SEARCH.equipNm1,
            this.GET_SEARCH.equipNm2,
            this.GET_SEARCH.equipNm3,
          ],
        },
        searchInfo: [
          this.GET_SEARCH.etcKeyword1,
          this.GET_SEARCH.etcKeyword2,
          this.GET_SEARCH.etcKeyword3,
          this.GET_SEARCH.etcKeyword4,
          this.GET_SEARCH.etcKeyword5,
          this.GET_SEARCH.etcKeyword6,
        ],
        secretInfo: [
          this.GET_SEARCH.secKeyword1,
          this.GET_SEARCH.secKeyword2,
          this.GET_SEARCH.secKeyword3,
        ],
      };
      this.setSelect("major", fetchedSearchInfo.major);

      this.form.majorNum.value = fetchedSearchInfo.majorNum;
      if (fetchedSearchInfo.majorNum !== "") {
        this.form.majorNum.flag = true;
      }

      this.form.majorDetail.value = fetchedSearchInfo.majorDetail;
      if (fetchedSearchInfo.majorDetail !== "") {
        this.form.majorDetail.flag = true;
        this.form.majorDetailNum.value = fetchedSearchInfo.majorDetailNum;
        this.form.majorDetailNum.flag = true;
      }

      this.form.majorDeep.value = fetchedSearchInfo.majorDeep;
      if (fetchedSearchInfo.majorDeep !== "") {
        this.form.majorDeep.flag = true;
      }

      this.setSelect("univercity", fetchedSearchInfo.univercity.value);

      this.form.affil.affilYn = this.GET_SEARCH.affilYn;
      this.form.affilMajor.affilMajorYn = this.GET_SEARCH.proYn;
      if (this.form.affilMajor.affilMajorYn === "Y") this.checkboxMajor = "Y";

      if (fetchedSearchInfo.univercity.value === "기타") {
        this.form.univercityEtc.flag = true;
        this.form.univercityEtc.value = fetchedSearchInfo.univercity.etcValue;
      }

      this.form.graduationYear.flag = true;
      this.form.graduationYear.value = fetchedSearchInfo.graduationYear;

      this.form.trainingHospital.value = fetchedSearchInfo.trainingHospital;
      if (fetchedSearchInfo.trainingHospital !== "") {
        this.form.trainingHospital.flag = true;
      }
      for (let i = 0; i < fetchedSearchInfo.patientInfo.diagnosis.length; i++) {
        if (fetchedSearchInfo.patientInfo.diagnosis[i] !== "") {
          this.form.patientInfo.diagnosis[i] =
            fetchedSearchInfo.patientInfo.diagnosis[i];
        }
      }
      for (let j = 0; j < fetchedSearchInfo.patientInfo.symptom.length; j++) {
        if (fetchedSearchInfo.patientInfo.symptom[j] !== "") {
          this.form.patientInfo.symptom[j] =
            fetchedSearchInfo.patientInfo.symptom[j];
        }
      }
      for (let k = 0; k < fetchedSearchInfo.patientInfo.operation.length; k++) {
        if (fetchedSearchInfo.patientInfo.operation[k] !== "") {
          this.form.patientInfo.operation[k] =
            fetchedSearchInfo.patientInfo.operation[k];
        }
      }
      for (let l = 0; l < fetchedSearchInfo.patientInfo.equipment.length; l++) {
        if (fetchedSearchInfo.patientInfo.equipment[l] !== "") {
          this.form.patientInfo.equipment[l] =
            fetchedSearchInfo.patientInfo.equipment[l];
        }
      }
      for (let m = 0; m < fetchedSearchInfo.patientInfo.equipment.length; m++) {
        if (fetchedSearchInfo.patientInfo.equipment[m] !== "") {
          this.form.patientInfo.equipment[m] =
            fetchedSearchInfo.patientInfo.equipment[m];
        }
      }
      for (let n = 0; n < fetchedSearchInfo.searchInfo.length; n++) {
        if (fetchedSearchInfo.searchInfo[n] !== "") {
          this.form.searchInfo[n] = fetchedSearchInfo.searchInfo[n];
        }
      }
      for (let n = 0; n < fetchedSearchInfo.secretInfo.length; n++) {
        if (fetchedSearchInfo.secretInfo[n] !== "") {
          this.form.secretInfo[n] = fetchedSearchInfo.secretInfo[n];
        }
      }
    },
    checkMarketing() {
      if (this.form.affil.affilYn === "Y") this.form.affil.affilYn = "N";
      else this.form.affil.affilYn = "Y";
    },
    checkMajor() {
      if (this.checkboxMajor === "Y")
        this.form.affilMajor.affilMajorYn = this.checkboxMajor;
      else this.form.affilMajor.affilMajorYn = "N";
    },
    setSelect(type, value) {
      if (type === "major") {
        this.form.major.value = value;
        if (value !== "") {
          this.form.major.flag = true;
        }
      } else if (type === "univercity") {
        this.form.univercity.value = value;
        this.form.univercity.flag = true;
      }
    },
    checkMajorDeep() {
      this.form.majorDeep.flag = false;
      this.form.majorDeep.hasError = true;
      const MajorDeep = this.form.majorDeep.value.replace(/\s/gi, "");
      if (MajorDeep === "") {
        this.form.majorDeep.hasError = false;
        return false;
      }
      this.form.majorDeep.flag = true;
      this.form.majorDeep.hasError = false;
      return true;
    },
    storeAvailable() {
      for (let i = 0; i < this.form.isStoreLock.length; i++) {
        if (this.form.isStoreLock[i].flag) {
          return false;
        }
      }
      return true;
    },
    checkFlag() {
      if (!this.form.major.flag || !this.storeAvailable()) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.major.flag) {
          this.form.major.hasError = true;
        }
        return false;
      }
      return true;
    },
    async store() {
      if (this.checkFlag()) {
        const userData = {
          drId: localStorage.getItem("drId"),
          proObj: this.form.major.value,
          proNo: this.form.majorNum.value,
          dtlProObj: this.form.majorDetail.value,
          dtlProNo: this.form.majorDetailNum.value,
          dtlMajor: this.form.majorDeep.value,
          affilYn: this.form.affil.affilYn,
          proYn: this.form.affilMajor.affilMajorYn,
          affilCollage:
            this.form.univercity.value !== "항목 선택" &&
            this.form.univercity.value !== "선택안함"
              ? this.form.univercity.value
              : "",
          affilCollCd: this.form.univercityEtc.value,
          gradYear: this.form.graduationYear.value,
          trainHosp: this.form.trainingHospital.value,
          diagNm1: this.form.patientInfo.diagnosis[0],
          diagNm2: this.form.patientInfo.diagnosis[1],
          diagNm3: this.form.patientInfo.diagnosis[2],
          symptomNm1: this.form.patientInfo.symptom[0],
          symptomNm2: this.form.patientInfo.symptom[1],
          symptomNm3: this.form.patientInfo.symptom[2],
          operNm1: this.form.patientInfo.operation[0],
          operNm2: this.form.patientInfo.operation[1],
          operNm3: this.form.patientInfo.operation[2],
          equipNm1: this.form.patientInfo.equipment[0],
          equipNm2: this.form.patientInfo.equipment[1],
          equipNm3: this.form.patientInfo.equipment[2],
          etcKeyword1: this.form.searchInfo[0],
          etcKeyword2: this.form.searchInfo[1],
          etcKeyword3: this.form.searchInfo[2],
          etcKeyword4: this.form.searchInfo[3],
          etcKeyword5: this.form.searchInfo[4],
          etcKeyword6: this.form.searchInfo[5],
          secKeyword1: this.form.secretInfo[0],
          secKeyword2: this.form.secretInfo[1],
          secKeyword3: this.form.secretInfo[2],
        };
        //console.log("수정API 날릴 검색데이터", userData);

        // 유효성 검사

        // 1. 아이디

        if (userData.drId === null || userData.drId === undefined)
          userData.drId = "";

        var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

        if (!drIdBoo.test(userData.drId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "로그인 확인";
          this.popupSet.content =
            "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. 전문과목

        if (userData.proObj === null || userData.proObj === undefined)
          userData.proObj = "";

        var proObjBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

        if (!proObjBoo.test(userData.proObj)) {
          this.SET_POPUP(true);
          this.popupSet.title = "전문과목 확인";
          this.popupSet.content =
            "전문과목의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 전문의번호
        if (userData.proNo === null || userData.proNo === undefined)
          userData.proNo = "";

        var proNoBoo = /^[0-9]{0,10}$/;

        if (!proNoBoo.test(userData.proNo)) {
          this.SET_POPUP(true);
          this.popupSet.title = "전문의번호 확인";
          this.popupSet.content = "전문의번호의 글자 수를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. 세부전문과목
        if (userData.dtlProObj === null || userData.dtlProObj === undefined)
          userData.dtlProObj = "";

        var dtlProObjBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|,|]{0,100}$/;

        if (!dtlProObjBoo.test(userData.dtlProObj)) {
          this.SET_POPUP(true);
          this.popupSet.title = "세부전문과목 확인";
          this.popupSet.content =
            "세부전문과목의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 5. 세부전문의번호
        if (userData.dtlProNo === null || userData.dtlProNo === undefined)
          userData.dtlProNo = "";

        var dtlProNoBoo = /^[0-9]{0,10}$/;

        if (!dtlProNoBoo.test(userData.dtlProNo)) {
          this.SET_POPUP(true);
          this.popupSet.title = "전문의번호 확인";
          this.popupSet.content = "전문의번호를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 6. 세부전공
        if (userData.dtlMajor === null || userData.dtlMajor === undefined)
          userData.dtlMajor = "";

        var dtlMajorBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

        if (!dtlMajorBoo.test(userData.dtlMajor)) {
          this.SET_POPUP(true);
          this.popupSet.title = "세부전공 확인";
          this.popupSet.content =
            "세부전공의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 7. 출신학교표시여부
        if (userData.affilYn === null || userData.affilYn === undefined)
          userData.affilYn = "";

        var affilYnBoo = /^[A-Z]{0,1}$/;

        if (!affilYnBoo.test(userData.affilYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "출신학교표시여부 확인";
          this.popupSet.content = "출신학교표시여부를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 8. 전문과목표시여부
        if (userData.proYn === null || userData.proYn === undefined)
          userData.proYn = "";

        var proYnBoo = /^[A-Z]{0,1}$/;

        if (!proYnBoo.test(userData.proYn)) {
          this.SET_POPUP(true);
          this.popupSet.title = "전문과목표시여부 확인";
          this.popupSet.content = "전문과목표시여부를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 출신대학
        if (
          userData.affilCollage === null ||
          userData.affilCollage === undefined
        )
          userData.affilCollage = "";

        if (
          userData.affilCollage.length < 0 ||
          userData.affilCollage.length > 100
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "출신대학 확인";
          this.popupSet.content =
            "출신대학의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 10. 출신대학코드
        if (userData.affilCollCd === null || userData.affilCollCd === undefined)
          userData.affilCollCd = "";

        var affilCollCdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,100}$/;

        if (!affilCollCdBoo.test(userData.affilCollCd)) {
          this.SET_POPUP(true);
          this.popupSet.title = "출신대학코드 확인";
          this.popupSet.content =
            "출신대학코드의 숫자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 11. 졸업년도
        if (userData.gradYear === null || userData.gradYear === undefined)
          userData.gradYear = "";

        var gradYearBoo = /^[0-9]{0,4}$/;

        if (!gradYearBoo.test(userData.gradYear)) {
          this.SET_POPUP(true);
          this.popupSet.title = "졸업년도 확인";
          this.popupSet.content =
            "졸업년도의 숫자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 12. 수련병원
        if (userData.trainHosp === null || userData.trainHosp === undefined)
          userData.trainHosp = "";

        if (userData.trainHosp.length < 0 || userData.trainHosp.length > 50) {
          this.SET_POPUP(true);
          this.popupSet.title = "수련병원 확인";
          this.popupSet.content =
            "수련병원의 글자 수 및 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 13. 진단명1
        if (userData.diagNm1 === null || userData.diagNm1 === undefined)
          userData.diagNm1 = "";

        var diagNm1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!diagNm1Boo.test(userData.diagNm1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "진단명1 확인";
          this.popupSet.content =
            "진단명1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 14. 진단명2
        if (userData.diagNm2 === null || userData.diagNm2 === undefined)
          userData.diagNm2 = "";

        var diagNm2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!diagNm2Boo.test(userData.diagNm2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "진단명2 확인";
          this.popupSet.content =
            "진단명2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 15. 진단명3
        if (userData.diagNm3 === null || userData.diagNm3 === undefined)
          userData.diagNm3 = "";

        var diagNm3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!diagNm3Boo.test(userData.diagNm3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "진단명3 확인";
          this.popupSet.content =
            "진단명3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 16. 증상명1
        if (userData.symptomNm1 === null || userData.symptomNm1 === undefined)
          userData.symptomNm1 = "";

        var symptomNm1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!symptomNm1Boo.test(userData.symptomNm1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "증상명1 확인";
          this.popupSet.content =
            "증상명1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 17. 증상명2
        if (userData.symptomNm2 === null || userData.symptomNm2 === undefined)
          userData.symptomNm2 = "";

        var symptomNm2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!symptomNm2Boo.test(userData.symptomNm2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "증상명2 확인";
          this.popupSet.content =
            "증상명2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 18. 증상명3
        if (userData.symptomNm3 === null || userData.symptomNm3 === undefined)
          userData.symptomNm3 = "";

        var symptomNm3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!symptomNm3Boo.test(userData.symptomNm3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "증상명3 확인";
          this.popupSet.content =
            "증상명3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 19. 수술/시술명1
        if (userData.operNm1 === null || userData.operNm1 === undefined)
          userData.operNm1 = "";

        var operNm1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!operNm1Boo.test(userData.operNm1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "수술/시술명1 확인";
          this.popupSet.content =
            "수술/시술명1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 20. 수술/시술명2
        if (userData.operNm2 === null || userData.operNm2 === undefined)
          userData.operNm2 = "";

        var operNm2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!operNm2Boo.test(userData.operNm2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "수술/시술명2 확인";
          this.popupSet.content =
            "수술/시술명2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 21. 수술/시술명3
        if (userData.operNm3 === null || userData.operNm3 === undefined)
          userData.operNm3 = "";

        var operNm3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!operNm3Boo.test(userData.operNm3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "수술/시술명3 확인";
          this.popupSet.content =
            "수술/시술명3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 22. 보유장비명1
        if (userData.equipNm1 === null || userData.equipNm1 === undefined)
          userData.equipNm1 = "";

        var equipNm1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!equipNm1Boo.test(userData.equipNm1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "보유장비명1 확인";
          this.popupSet.content =
            "보유장비명1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 23. 보유장비명2
        if (userData.equipNm2 === null || userData.equipNm2 === undefined)
          userData.equipNm2 = "";

        var equipNm2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!equipNm2Boo.test(userData.equipNm2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "보유장비명2 확인";
          this.popupSet.content =
            "보유장비명2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 24. 보유장비명3
        if (userData.equipNm3 === null || userData.equipNm3 === undefined)
          userData.equipNm3 = "";

        var equipNm3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!equipNm3Boo.test(userData.equipNm3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "보유장비명3 확인";
          this.popupSet.content =
            "보유장비명3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 25. 기타검색정보1
        if (userData.etcKeyword1 === null || userData.etcKeyword1 === undefined)
          userData.etcKeyword1 = "";

        var etcKeyword1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword1Boo.test(userData.etcKeyword1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보1 확인";
          this.popupSet.content =
            "기타 검색정보1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 26. 기타검색정보2
        if (userData.etcKeyword2 === null || userData.etcKeyword2 === undefined)
          userData.etcKeyword2 = "";

        var etcKeyword2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword2Boo.test(userData.etcKeyword2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보2 확인";
          this.popupSet.content =
            "기타 검색정보2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 27. 기타검색정보3
        if (userData.etcKeyword3 === null || userData.etcKeyword3 === undefined)
          userData.etcKeyword3 = "";

        var etcKeyword3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword3Boo.test(userData.etcKeyword3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보3 확인";
          this.popupSet.content =
            "기타 검색정보3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 28. 기타검색정보4
        if (userData.etcKeyword4 === null || userData.etcKeyword4 === undefined)
          userData.etcKeyword4 = "";

        var etcKeyword4Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword4Boo.test(userData.etcKeyword4)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보4 확인";
          this.popupSet.content =
            "기타 검색정보4의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 29. 기타검색정보5
        if (userData.etcKeyword5 === null || userData.etcKeyword5 === undefined)
          userData.etcKeyword5 = "";

        var etcKeyword5Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword5Boo.test(userData.etcKeyword5)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보5 확인";
          this.popupSet.content =
            "기타 검색정보5의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 30. 기타검색정보6
        if (userData.etcKeyword6 === null || userData.etcKeyword6 === undefined)
          userData.etcKeyword6 = "";

        var etcKeyword6Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!etcKeyword6Boo.test(userData.etcKeyword6)) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타 검색정보6 확인";
          this.popupSet.content =
            "기타 검색정보6의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 31. 비밀 검색정보1
        if (userData.secKeyword1 === null || userData.secKeyword1 === undefined)
          userData.secKeyword1 = "";

        var secKeyword1Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!secKeyword1Boo.test(userData.secKeyword1)) {
          this.SET_POPUP(true);
          this.popupSet.title = "비밀 검색정보1 확인";
          this.popupSet.content =
            "비밀 검색정보1의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 32. 비밀 검색정보2
        if (userData.secKeyword2 === null || userData.secKeyword2 === undefined)
          userData.secKeyword2 = "";

        var secKeyword2Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!secKeyword2Boo.test(userData.secKeyword2)) {
          this.SET_POPUP(true);
          this.popupSet.title = "비밀 검색정보2 확인";
          this.popupSet.content =
            "비밀 검색정보2의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 33. 비밀 검색정보3
        if (userData.secKeyword3 === null || userData.secKeyword3 === undefined)
          userData.secKeyword3 = "";

        var secKeyword3Boo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{0,30}$/;

        if (!secKeyword3Boo.test(userData.secKeyword3)) {
          this.SET_POPUP(true);
          this.popupSet.title = "비밀 검색정보3 확인";
          this.popupSet.content =
            "비밀 검색정보3의 글자 수 및 공백, 특수문자를 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }
        try {
          await this.MODIFY_SEARCH(userData).then((data) => {
            if (data > 900) {
              this.SET_POPUP(true);
              this.popupSet.title = "검색정보 저장 실패";
              this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            }

            this.SET_POPUP(true);
            this.popupSet.title = "검색정보 등록성공";
            this.popupSet.content = "검색정보 성공적으로 등록되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/join/enteredDone";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
    checkTrainingHospital() {
      this.form.trainingHospital.flag = false;
      this.form.trainingHospital.hasError = true;
      const TrainingHospital = this.form.trainingHospital.value.replace(
        /\s/gi,
        ""
      );
      if (TrainingHospital === "") {
        return false;
      }
      this.form.trainingHospital.flag = true;
      this.form.trainingHospital.hasError = false;
      return true;
    },
    checkMajorDetailNum() {
      this.form.majorDetailNum.flag = false;
      this.form.majorDetailNum.hasError = true;
      this.form.isStoreLock[1].flag = true;
      const MajorDetailNum = this.form.majorDetailNum.value.replace(/\s/gi, "");
      if (MajorDetailNum === "") {
        this.form.majorDetailNum.hasError = true;
        return false;
      }
      this.form.majorDetailNum.flag = true;
      this.form.majorDetailNum.hasError = false;
      this.form.isStoreLock[1].flag = false;
      return true;
    },

    checkMajorNum() {
      this.form.majorNum.flag = false;
      this.form.majorNum.hasError = true;
      this.form.isStoreLock[0].flag = true;
      const MajorNum = this.form.majorNum.value.replace(/\s/gi, "");
      if (MajorNum === "") {
        this.form.majorNum.hasError = true;
        return false;
      }
      this.form.majorNum.flag = true;
      this.form.majorNum.hasError = false;
      this.form.isStoreLock[0].flag = false;
      return true;
    },
    initMajorNum() {
      this.form.majorNum.value = "";
      this.form.majorNum.hasError = false;
      this.form.majorNum.flag = false;
    },
    checkMajorDetail() {
      if (this.form.majorDetail.value !== null) {
        this.form.majorDetail.flag = false;
        this.form.majorDetail.hasError = true;
        this.form.isStoreLock[1].flag = true;
        this.form.majorDetailNum.flag = false;
        this.form.majorDetailNum.hasError = false;
        this.form.majorDetailNum.value = "";
        const MajorDetail = this.form.majorDetail.value.replace(/\s/gi, "");
        if (MajorDetail === "") {
          this.form.majorDetail.hasError = false;
          this.form.isStoreLock[1].flag = false;
          return false;
        }

        this.form.majorDetail.flag = true;
        this.form.majorDetail.hasError = false;
        this.form.majorDetailNum.hasError = true;
        return true;
      }
    },
    checkGraduationYear() {
      if (this.form.graduationYear.value !== null) {
        this.form.graduationYear.flag = false;
        this.form.graduationYear.hasError = true;
        const GraduationYear = this.form.graduationYear.value.replace(
          /\s/gi,
          ""
        );
        if (GraduationYear === "" || GraduationYear.length !== 4) {
          this.form.isStoreLock[2].flag = true;
          return false;
        }
        this.form.graduationYear.flag = true;
        this.form.graduationYear.hasError = false;
        this.form.isStoreLock[2].flag = false;
        return true;
      }
    },
    initUniverCityEtc() {
      this.form.graduationYear.value = "";
      this.form.graduationYear.hasError = false;
      this.form.graduationYear.flag = false;
    },
    checkUnivercityEtc() {
      this.form.univercityEtc.flag = false;
      this.form.univercityEtc.hasError = true;
      this.form.isStoreLock[2].flag = true;
      this.initUniverCityEtc();
      const UnivercityEtc = this.form.univercityEtc.value.replace(/\s/gi, "");
      if (UnivercityEtc === "") {
        return false;
      }
      this.form.univercityEtc.flag = true;
      this.form.univercityEtc.hasError = false;
      this.form.graduationYear.hasError = true;
    },
    initMajorData() {
      this.form.majorNum.value = "";
      this.form.majorNum.flag = false;
    },
    async isMajor() {
      this.$refs.major.$el.parentElement.classList.remove("caution");
      await this.initMajorData();
      this.form.major.flag = true;
      this.form.major.hasError = false;
      this.form.majorNum.hasError = true;
      if (this.form.major.value !== "일반의") {
        this.form.isStoreLock[0].flag = true;
      } else {
        this.form.isStoreLock[0].flag = false;
      }
    },
    isMajorDetail() {
      this.$refs.majorDetail.parentElement.classList.remove("caution");
      this.form.majorDetail.hasError = false;
    },
    initUnivercityData() {
      this.form.graduationYear.value = "";
      this.form.graduationYear.flag = false;
      this.form.graduationYear.hasError = false;
      this.form.univercityEtc.flag = false;
      this.form.univercityEtc.value = "";
      this.form.univercityEtc.hasError = false;
    },
    isUnivercity() {
      this.initUnivercityData();
      this.form.univercity.flag = true;
      this.form.univercity.hasError = false;
      if (
        this.$refs.univercity.$el.parentElement.classList.contains("caution")
      ) {
        this.$refs.univercity.$el.parentElement.classList.remove("caution");
      }
      if (this.form.univercity.value === "기타") {
        this.form.isStoreLock[2].flag = true;
        this.form.univercityEtc.hasError = true;
      } else if (this.form.univercity.value === "선택안함") {
        this.form.graduationYear.hasError = false;
        this.form.isStoreLock[2].flag = false;
      } else {
        this.form.isStoreLock[2].flag = true;
        this.form.graduationYear.hasError = true;
      }
    },
    selectInputClick(textData) {
      if (
        (textData === "major" && this.form.major.value === "항목 선택") ||
        this.form.major.value === ""
      ) {
        this.form.major.hasError = true;
        this.$refs.major.$el.parentElement.classList.add("caution");
        this.form.isStoreLock[0].flag = true;
      } else if (
        textData === "majorDetail" &&
        this.form.majorDetail.value === ""
      ) {
        this.form.majorDetail.hasError = true;
        this.$refs.majorDetail.parentElement.classList.add("caution");
        this.form.isStoreLock[1].flag = true;
      } else if (textData === "univercity") {
        if (this.form.univercity.value === "항목 선택") {
          this.form.univercity.hasError = true;
          this.$refs.univercity.$el.parentElement.classList.add("caution");
          this.form.isStoreLock[2].flag = true;
        } else if (
          this.form.univercity.value === "기타" &&
          this.form.univercityEtc.value === ""
        ) {
          this.form.univercityEtc.hasError = true;
          this.form.isStoreLock[2].flag = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.custom-table {
  background-color: #f5f9fa;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.custom-table-cell {
  align-items: center;
  display: flex;
}
.custom-table-row {
  border-bottom: 1px solid lightgrey;
}
.background-cell {
  background-color: white;
}

.search_btn-first {
    margin-right: 20px;
}

.search-container {
  margin-bottom: 50px;
}

@media screen and (max-width: 340px){
  .search_btn-first {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}


</style>
